import '../sass/components/product-search.scss'

import Selects from "./Selects";
import React, {useContext, useEffect, useState} from "react";
import ProductList from "./ProductList";
import {fetchSearchOptions} from "../apiRequests/api";
import {useAuth0} from "@auth0/auth0-react";
import {filterFields} from "../settings";
import {SigninContext} from "../contexts/SigninContext";
import Loading from "./Loading";


function ProductSearch () {
    const [selectsLoading, setSelectsLoading] = useState(true)
    const { userName } = useContext(SigninContext)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [filterStates, setFilterStates] = useState(filterFields.map(el=>''))
    const [filterInfo, setFilterInfo] = useState(filterFields.map(el=>el))
    const [fetchParameters, setFetchParameters] = useState(
        {
            "productsSearchParameters": {
                'description': '',
                'colour': '',
                'organic/biodynamic': '',
                'closure': '',
                'country': '',
                'supergroup': '',
                'productgroup': '',
            }
        }
    )

    // equalize fetch parameters to states of search filters
    function searchProducts () {
        setIsSubmitted(true)
        let fetchObj = {"productsSearchParameters":{}}
        filterFields.forEach((el, index) => {
            fetchObj.productsSearchParameters[el.name] = filterStates[index]
        })
        setFetchParameters(fetchObj)
    }
    const {getIdTokenClaims} = useAuth0()
    // we need to request options first from api to be rendered for search selects
    async function loadOptions() {
        const token = await getIdTokenClaims()
        const data = await fetchSearchOptions(token.__raw)
        if (!data.error) {
            setFilterInfo(filterInfo.map(state => {
                if (state.select) {
                    state.options = data.mainResult[state.name]
                    return state
                } else {
                    return state
                }
            }))
            setSelectsLoading(false)
        }
    }
    function handleFilterChange (val, order) {
        const newArr = filterStates
        newArr[order] = val
        setFilterStates(filterStates.map((el, index) => index === order ? val : el))
    }
    useEffect(() => {
        loadOptions()
    },[])

    return (
        <div className="product-search-page">
            <h2 className='product-search-page-title'>Product Search</h2>
            <Loading isLoading={selectsLoading}/>
            <div className="product-search-filters">
                {!selectsLoading &&
                    filterFields.map((field, index) => (
                        field.select? (
                            <div key={index} className="product-search-tool product-search-select">
                                <p>{field.title}</p>
                                <Selects options={filterInfo[index].options} setPropsValue={handleFilterChange} value={filterStates[index]} order={index}/>
                            </div>
                        ):(
                            <div key={index} className="product-search-tool">
                                <p>{field.title}</p>
                                <input type={"text"}
                                       value={filterStates[index]}
                                       name={field.name}
                                       onChange={(e) => handleFilterChange(e.target.value, index)}
                                />
                            </div>
                        )
                    ))
                }
                {!selectsLoading &&
                    <button onClick={() => searchProducts()} className="product-search-tool product-search-button">Search</button>
                }
            </div>
            {isSubmitted && <ProductList fetchParams={fetchParameters}/>}
        </div>
    )
}
export default ProductSearch