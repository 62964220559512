import {useParams, Link, useHistory} from "react-router-dom";
import React, {useState, useEffect, useContext} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {fetchOneOrder} from "../apiRequests/api";
import Loading from "./Loading";
import "../sass/components/order-details.scss";
import "../sass/components/product-item.scss";
import "../sass/components/basket.scss";
import OrderItem from "./OrderItem";
import Totals from "./Totals";

import printIcon from "./../assets/print_icon.svg"
import {SigninContext} from "../contexts/SigninContext";

export default function OrderDetails() {
    const {orderNo} = useParams()
    const [isLoading, setLoading] = useState(true)
    const [order, setOrder] = useState({})
    const [orderArray, setOrderArray] = useState([])
    const [totalVAT, setTotalVAT] = useState(0);
    const [totalNett, setTotalNett] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const {getIdTokenClaims} = useAuth0();
    const backToOrder = "<< Back to 'My Orders'";
    const history = useHistory()
    const {userName} = useContext(SigninContext)

    async function loadOrder() {
        let temp_array = []
        const token = await getIdTokenClaims()
        setLoading(true)
        if (orderNo) {
            const token = await getIdTokenClaims()
            const singleOrder = (await fetchOneOrder(orderNo, userName, token.__raw)).mainResult
            setOrder(singleOrder)
            if (singleOrder && singleOrder.OrderLines && singleOrder.OrderLines.OrderLine) {
                if (Array.isArray(singleOrder.OrderLines.OrderLine)) {
                    temp_array = singleOrder.OrderLines.OrderLine;
                } else {
                    temp_array.push(singleOrder.OrderLines.OrderLine);
                }
                setOrderArray(temp_array);
                setTotalNett(temp_array.reduce((sum, order) => (sum + order.LineNett), 0));
                setTotalVAT(temp_array.reduce((sum, order) => (sum + order.LineVAT), 0));
                setGrandTotal(temp_array.reduce((sum, order) => (sum + order.LineVAT + order.LineNett), 0));
            }
            setLoading(false)
        }
    }

    function updateBasketHandler() {
        var addButtons = document.querySelectorAll('.update-cart-product-list-table')
        addButtons.forEach(el => el.click())
        history.push(`/basket/`);
    }

    useEffect(() => {
        loadOrder()
    }, [])

    return (
        <div>
            <Loading isLoading={isLoading}/>
            {order.Delivery_Name &&
                <>
                    <div className="order-details-title">
                        <Link to={'/orders'} className="order-details-link"><h3>{backToOrder}</h3></Link>
                        <h3>{order.Billing_Name}</h3>
                    </div>
                    <div className="order-details-header">
                        <h2>Order</h2>
                        <span className="print_icon" onClick={() => window.print()}><i
                        className="fa-3x fa-sharp fa-regular fa-print"></i></span>
                    </div>

                    {
                        <div className={`order-details ${!isLoading ? "order-details-show" : "order-details-hide"}`}>
                            <div className="order-details-orderNo">
                                <p className="order-details-bold">Order: </p><span>{orderNo}</span>
                            </div>
                            <div className="order-details-orderDate">
                                <p className="order-details-bold">Order Date: </p>
                                <span>{new Date(order.Order_OrderDate).toLocaleDateString()}</span>
                            </div>
                            {order.Order_CustomerReference && (
                                <div className="order-details-customerRef">
                                    <p className="order-details-bold">Customer Ref: </p>
                                    <span>{order.Order_CustomerReference}</span>
                                </div>
                            )}
                            <div className="order-details-deliveryAddress">
                                <p className="order-details-bold">Delivery Address: </p>
                                {order.Delivery_Name && <p>{order.Delivery_Name}</p>}
                                {order.Delivery_Address1 && <p>{order.Delivery_Address1}</p>}
                                {order.Delivery_Address2 && <p>{order.Delivery_Address2}</p>}
                                {order.Delivery_Address3 && <p>{order.Delivery_Address3}</p>}
                                {order.Delivery_Address4 && <p>{order.Delivery_Address4}</p>}
                                {order.Delivery_Address5 && <p>{order.Delivery_Address5}</p>}
                            </div>
                            <div className="order-details-billingAddress">
                                <p className="order-details-bold">Billing Address: </p>
                                {order.Billing_Name && <p>{order.Billing_Name}</p>}
                                {order.Billing_Address1 && <p>{order.Billing_Address1}</p>}
                                {order.Billing_Address2 && <p>{order.Billing_Address2}</p>}
                                {order.Billing_Address3 && <p>{order.Billing_Address3}</p>}
                                {order.Billing_Address4 && <p>{order.Billing_Address4}</p>}
                                {order.Billing_Address5 && <p>{order.Billing_Address5}</p>}
                            </div>
                            {order.Delivery_Notes && <div className="order-details-delivery-notes">
                                {order.Delivery_Notes}
                            </div>}
                            <div className='product-list'>
                                <p className="product-list-remark">* The products and quantities from the order have
                                    been loaded below. Please click 'Re-order' to add these to your basket.</p>
                                <div className="order-details-re-order">
                                    <button onClick={() => updateBasketHandler()}>Re-order</button>
                                </div>
                                <div className={"items"}>
                                    <div className={"item-title"}>
                                        Product
                                    </div>
                                    {/*<div className={"item-code"}>*/}
                                    {/*  Code*/}
                                    {/*</div>*/}
                                    {/*<div className={"item-qty"}>*/}
                                    {/*    Case Qty*/}
                                    {/*</div>*/}
                                    <div className={"item-qty"}>
                                        Bottle Qty
                                    </div>
                                    <div className={"item-net"}>
                                        Nett
                                    </div>
                                    <div className={"item-vat"}>
                                        VAT
                                    </div>
                                </div>
                                {
                                    order && order.OrderLines && order.OrderLines.OrderLine &&
                                    orderArray.length && orderArray.map(
                                        (order, index) => (
                                            <div className='order-item' key={index}>
                                                <OrderItem
                                                    bottles={order.TotalBottles}
                                                    id={order.ProductCode}
                                                    lineNett={order.LineNett}
                                                    lineVAT={order.LineVAT}
                                                    lineTitle={order.Description}
                                                    cases="0"
                                                />
                                            </div>
                                        )
                                    )
                                }

                            </div>

                            <Totals totalNett={totalNett} totalVAT={totalVAT} grandTotal={grandTotal}/>
                        </div>}
                    {/* {order && <pre>{JSON.stringify(order,' ',3)}</pre>} */}
                </>
            }
        </div>
    )
}