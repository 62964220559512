const url = process.env.REACT_APP_BACKEND_API_BASE_URL
const urlAnon = process.env.REACT_APP_BACKEND_API_BASE_URL.slice(0, -1) + '-anon/'
const errorMessage = 'Service unavailable, try again later.'

// api calls lib
export const fetchOneProduct = (prodCode, token) => {
    return fetch(url + 'products/item', {
        method: 'POST',
        headers: {'authorization': `Bearer ${token}`},
        body: JSON.stringify(prodCode)
    })
        .then((response) => {
            return response.json();
        })
}
export const fetchProducts = (fetchParameters, token) => {
    return fetch(url + 'products/items', {
        method: 'POST',
        headers: {'authorization': `Bearer ${token}`},
        body: JSON.stringify(fetchParameters)
    })
        .then((response) => {
            if (response.status === 204) {
                return {error: 'No results found. Please amend your search criteria and try again.'}
            } else {
                return response.json();
            }
        })
        .catch(() => {
            return {error: errorMessage}
        });
}
export const fetchReserves = (fetchParameters, token) => {
    return fetch(url + 'reserves/items', {
        method: 'POST',
        headers: {'authorization': `Bearer ${token}`},
        body: JSON.stringify(fetchParameters)
    })
        .then((response) => {
            if (response.status === 204) {
                return {error: 'No results found. Please amend your search criteria and try again.'}
            } else {
                return response.json();
            }
        })
        .catch(() => {
            return {error: errorMessage}
        });
}
export const fetchReservesTotals = (token) => {
    return fetch(url + 'reserves/totals', {
        method: 'POST',
        headers: {'authorization': `Bearer ${token}`},
    })
        .then((response) => {
            if (response.status === 204) {
                return {error: 'No results found. Please amend your search criteria and try again.'}
            } else {
                return response.json();
            }
        })
        .catch(() => {
            return {error: errorMessage}
        });
}
export const fetchOneReserve = (prodCode, token) => {
    return fetch(url + 'reserves/item', {
        method: 'POST',
        headers: {'authorization': `Bearer ${token}`},
        body: JSON.stringify(prodCode)
    })
        .then((response) => {
            return response.json();
        })
}
export const fetchSearchOptions = (token) => {
    return fetch(url + 'products/search-filters', {
        method: 'POST',
        headers: {'authorization': `Bearer ${token}`},
    })
        .then((response) => {
            return response.json();
        })
        .catch(() => {
            return {error: errorMessage}
        });
}
export const fetchSearched = (fetchParameters, token) => {
    return fetch(url + 'products/search-filters', {
        method: 'POST',
        headers: {'authorization': `Bearer ${token}`},
        body: JSON.stringify(fetchParameters)
    })
        .then((response) => {
            return response.json();
        })
        .catch(() => {
            return {error: errorMessage}
        });
}
export const fetchDeliveryAddresses = (fetchParameters, token) => {
    return fetch(url + 'delivery/addresses', {
        method: 'POST',
        headers: {'authorization': `Bearer ${token}`},
        body: JSON.stringify(fetchParameters)
    })
        .then((response) => {
            return response.json();
        })
        .catch(() => {
            return {error: errorMessage}
        });
}
export const fetchCustomerAccounts = (token) => {
    return fetch(url + 'account/accounts', {
        method: 'POST',
        headers: {'authorization': `Bearer ${token}`},
    })
        .then((response) => {
            return response.json();
        })
        .catch(() => {
            return {error: errorMessage}
        });
}

export const fetchSubmitOrder = (fetchParameters, token) => {
    return fetch(url + 'submit/order', {
        method: 'POST',
        headers: {'authorization': `Bearer ${token}`},
        body: JSON.stringify(fetchParameters)
    })
        .then((response) => {
            return response.json();
        })
}

export const fetchSubmitReservesOrder = (fetchParameters, token) => {
    return fetch(url + 'submit/reserves-order', {
        method: 'POST',
        headers: {'authorization': `Bearer ${token}`},
        body: JSON.stringify(fetchParameters)
    })
        .then((response) => {
            return response.json();
        })
}

export const fetchOrders = (fetchParameters, token) => {
    return fetch(url + 'account/orders/items', {
        method: 'POST',
        headers: {'authorization': `Bearer ${token}`},
        body: JSON.stringify(fetchParameters)
    })
        .then((response) => {
            if (response.status === 204) {
                return {error: 'No results found. Please amend your search criteria and try again.'}
            } else {
                return response.json();
            }
        })
        .catch(() => {
            return {error: errorMessage}
        });
}

export const fetchOneOrder = (orderNo, userName, token) => {
    return fetch(url + 'account/orders/item', {
        method: 'POST',
        headers: {'authorization': `Bearer ${token}`},
        body: JSON.stringify({"orderNo": orderNo, "CustomerAccount": userName})
    })
        .then((response) => {
            return response.json();
        })
}

export const fetchOneOrderEmail = (order, userName, token, backOrder) => {
    return fetch(url + 'account/orders/item-email', {
        method: 'POST',
        headers: {'authorization': `Bearer ${token}`},
        body: JSON.stringify({"orderNo": order, "CustomerAccount": userName, "backOrder": backOrder})
    })
    .then((response) => {
        return response.json();
    });
}

export const fetchTransactions = (fetchParameters, token) => {
    return fetch(url + 'account/transactions', {
        method: 'POST',
        headers: {'authorization': `Bearer ${token}`},
        body: JSON.stringify(fetchParameters)
    })
        .then((response) => {
            if (response.status === 204) {
                return {error: 'No results found. Please amend your search criteria and try again.'}
            } else {
                return response.json();
            }
        })
        .catch(() => {
            return {error: errorMessage}
        });
}

export const fetchCustomer = (fetchParameters, token) => {
    return fetch(url + 'account', {
        method: 'POST',
        headers: {'authorization': `Bearer ${token}`},
        body: JSON.stringify(fetchParameters),
    })
        .then((response) => {
            return response.json();
        })
        .catch(() => {
            return {error: errorMessage}
        });
}

export const fetchDeliveryDays = (fetchParameters, token) => {
    return fetch(url + 'delivery/days', {
        method: 'POST',
        headers: {'authorization': `Bearer ${token}`},
        body: JSON.stringify(fetchParameters),
    })
        .then((response) => {
            return response.json();
        })
        .catch(() => {
            return {error: errorMessage}
        });
}

export const fetchNextDeliveryDate = (fetchParameters, token) => {
    return fetch(url + 'delivery/date', {
        method: 'POST',
        headers: {'authorization': `Bearer ${token}`},
        body: JSON.stringify(fetchParameters),
    })
        .then((response) => {
            return response.json();
        })
        .catch(() => {
            return {error: errorMessage}
        });
}

export const fetchSubmitProspect = (fetchParameters) => {
    return fetch(urlAnon + 'submit/prospect', {
        method: 'POST',
        body: JSON.stringify(fetchParameters),
    })
        .then((response) => {
            return response.json();
        })
        .catch(() => {
            return {error: errorMessage}
        });
}


export const fetchPaymentVintner = (fetchParameters, token) => {
    return fetch(url + 'payments/vintner/add_payment', {
        method: 'POST',
        headers: {'authorization': `Bearer ${token}`},
        body: JSON.stringify(fetchParameters)
    })
        .then((response) => {
            return response.json();
        })
}

export const fetchStripeCheckoutSession = (fetchParameters, token) => {
    return fetch(url + 'payments/stripe/create-checkout-session', {
        method: 'POST',
        headers: {'authorization': `Bearer ${token}`},
        body: JSON.stringify(fetchParameters)
    })
        .then((response) => {
            return response.json();
        })
}

export const fetchConfirmOrder = (orderNo, userName, token) => {
    return fetch(url + 'confirm/order', {
        method: 'POST',
        headers: {'authorization': `Bearer ${token}`},
        body: JSON.stringify({"orderNo": orderNo, "CustomerAccount": userName}),
    })
        .then((response) => {
            return response.json();
        })
}