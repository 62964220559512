import {React, useContext, useEffect, useRef, useState} from "react";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {
    fetchCustomer,
    fetchDeliveryAddresses,
    fetchDeliveryDays,
    fetchNextDeliveryDate,
    fetchReserves
} from "../apiRequests/api";
import {useAuth0} from "@auth0/auth0-react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import Loading from "./Loading";
import Error from "./Error";
import {closures, requiredQtyBottles} from "../settings";
import logo from "../logo.webp";
import * as Modules from "./Modules";
import BasketListCheckout from "./BasketListCheckout";
import {SigninContext} from "../contexts/SigninContext";
import DatePicker from "react-datepicker";
import "../sass/components/checkout.scss"
import {useBasePath} from "../hooks/useBasePath";
import toArray from "yup/es/util/toArray";


function Checkout() {
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    const [delivery, setDelivery] = useState([])
    const [overrideDeliveryAddress, setOverrideDeliveryAddress] = useState(false)
    const [deliveryDays, setDeliveryDays] = useState('')
    const [deliveryDate, setDeliveryDate] = useState('')
    const [closestSet, setClosestSet] = useState(false)
    const [deliveryMethod, setDeliveryMethod] = useState('Delivery')
    const [deliveryDateFormatted, setDeliveryDateFormatted] = useState('')
    const [deliveryTimeFormatted, setDeliveryTimeFormatted] = useState('')
    const [deliveryIndex, setDeliveryIndex] = useState(0)
    const [customerRef, setCustomerRef] = useState('')
    const [deliveryNotes, setDeliveryNotes] = useState('')
    const [isDeliveryLoading, setIsDeliveryLoading] = useState(true)
    const [isDeliveryDaysLoading, setIsDeliveryDaysLoading] = useState(true)
    const [customDelivery, setCustomDelivery] = useState(false)
    const [customDeliveryAddress, setCustomDeliveryAddress] = useState([])
    const [isErrorM, setIsErrorM] = useState(false)
    const [Message, setMessage] = useState('')
    const [OrderN, setOrderN] = useState('')
    const {getIdTokenClaims} = useAuth0()
    const [isLoading, setLoading] = useState(true)
    const [customer, setCustomer] = useState([])
    const {userName} = useContext(SigninContext)
    const cart = useSelector((state) => state.cart)
    const [startDate, setStartDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);


    const isWeekday = (date) => {
        const day = date.getDay();
        if (deliveryDays) {
            return deliveryDays.includes(day);
        } else {
            // Default to Weekdays if not set for customer
            setDeliveryDays('1|2|3|4|5');
            return deliveryDays.includes(day);
        }
    }
    const filterTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        const openHours = process.env.REACT_APP_COLLECTION_TIMES ?? [9, 10, 11, 12, 13, 14, 15, 16, 17];
        return currentDate.getTime() < selectedDate.getTime() && openHours.includes(selectedDate.getHours());
    };

    let totalBottles = 0
    cart.items && cart.items.forEach(el => {
        totalBottles += (el.CaseSize * el.CasesQty) + el.BottlesQty
    })
    let totalNett = 0
    let totalVAT = 0
    let grandTotal = 0
    //total math
    cart.items && cart.items.forEach(el => {
        totalNett += el.CasePrice * el.CasesQty + el.BottlesQty * el.BottleNett
        totalVAT += el.BottleVAT * (el.CasesQty * el.CaseSize + el.BottlesQty)
        grandTotal = totalNett + totalVAT
    })

    let totalKegs = 0
    cart.items && cart.items.forEach(el => {
        closures.forEach(closure => {
            if (el.ClosureStyle == closure) {
                totalKegs += 1
            }
        })
    })


    let btnCreditRef = useRef();
    let btnCardRef = useRef();
    let history = useHistory()
    const dispatch = useDispatch()

    const basePath = useBasePath();


    // building parameters to call api
    let fetchParameters = {
        "basePath": basePath,
        "CustomerAccount": userName
    }

    function onChangeHandler(value, custom) {
        setDeliveryIndex(value)
        if (custom == "Collection") {
            setCustomDelivery(true)
            setDeliveryMethod('Collection')
            setOverrideDeliveryAddress(true)
            setDeliveryDateFormatted(new Date(today).toLocaleDateString("en-UK"))
            let currentHour = new Date().getHours() + 1;
            let array = process.env.REACT_APP_COLLECTION_TIMES ?? [9, 10, 11, 12, 13, 14, 15, 16, 17];
            let closest = array.sort((a, b) => Math.abs(currentHour - a) - Math.abs(currentHour - b))[0];
            {
                setStartDate(new Date().setHours(closest, 0, 0, 0))
            }

        } else {
            setCustomDelivery(false)
            setCustomDeliveryAddress([])
            setDeliveryMethod('Delivery')
            setStartDate(tomorrow)
            setDeliveryDateFormatted(new Date(tomorrow).toLocaleDateString("en-UK"))
        }
    }

    async function loadCustomer() {
        const token = await getIdTokenClaims()
        const data = await fetchCustomer(fetchParameters, token.__raw)
        if (!data.error) {
            setCustomer(data.CreditLimit)
        }
        setCustomer(data)
        setDeliveryDateFormatted(new Date(tomorrow).toLocaleDateString("en-UK"))
        console.log(data.error)
    }

    async function loadDeliveryAddresses() {
        setIsDeliveryLoading(true)
        const token = await getIdTokenClaims()
        const data = await fetchDeliveryAddresses(fetchParameters, token.__raw)
        if (!data.error) {
            setDeliveryNotes(data[deliveryIndex].DeliveryNotes1)
        }
        setDelivery(data)
        setIsDeliveryLoading(false)
        console.log(data.error)
    }

    async function loadDeliveryDays() {
        setIsDeliveryLoading(true)
        const token = await getIdTokenClaims()
        const data = await fetchDeliveryDays(fetchParameters, token.__raw)
        setDeliveryDays(data)
        setIsDeliveryLoading(false)
        console.log(data.error)

    }

    async function loadNextDeliveryDate() {
        setIsDeliveryLoading(true)
        const token = await getIdTokenClaims()
        const data = await fetchNextDeliveryDate(fetchParameters, token.__raw)
        setDeliveryDate(data)
        setDeliveryDateFormatted(new Date(data).toLocaleDateString("en-UK"))


        if (new Date(data).toLocaleDateString("en-UK") == new Date().toLocaleDateString("en-UK")) {
            setStartDate(tomorrow);
        } else {
            setStartDate(new Date(data))
        }
        setIsDeliveryLoading(false)
        console.log(data.error)

    }

    async function loadReserves() {
        const token = await getIdTokenClaims()
        if (token) {

            if (process.env.REACT_APP_RESERVES_ORDERS === "1") {
                const data = await fetchReserves(fetchParameters, token.__raw)

                if (data.error) {
                } else {
                    cart.reserves = false
                    cart.stock = false

                    cart.items && cart.items.forEach(el => {

                        if (in_array(data.mainResult, el.Code)) {
                            var reserve = in_array(data.mainResult, el.Code)
                            var orderQty = el.BottlesQty + el.CasesQty * el.CaseSize
                            var reserveQty = reserve.FreeCases * el.CaseSize + reserve.FreeBottles
                            var fromReserves = orderQty - (Math.max(0, orderQty - reserveQty))
                            var fromStock = orderQty - fromReserves
                            cart.reserves = true
                            el.CasesReservesQty = Math.floor(fromReserves / el.CaseSize)
                            el.BottlesReservesQty = fromReserves % el.CaseSize
                            el.CasesStockQty = Math.floor(fromStock / el.CaseSize)
                            el.BottlesStockQty = fromStock % el.CaseSize

                        } else {
                            el.CasesReservesQty = 0
                            el.BottlesReservesQty = 0
                            el.CasesStockQty = el.CasesQty
                            el.BottlesStockQty = el.BottlesQty
                        }

                    })
                    cart.items && cart.items.forEach(el => {
                        var orderQty = el.BottlesStockQty + el.CasesStockQty * el.CaseSize
                        if (orderQty > 0) {
                            cart.stock = true
                        }
                    })
                }

            } else {
                cart.items && cart.items.forEach(el => {
                    el.CasesReservesQty = 0
                    el.BottlesReservesQty = 0
                    el.CasesStockQty = el.CasesQty
                    el.BottlesStockQty = el.BottlesQty
                    var orderQty = el.BottlesStockQty + el.CasesStockQty * el.CaseSize
                    if (orderQty > 0) {
                        cart.stock = true
                        cart.reserves = false
                    }
                })
            }
        }


    }

    function in_array(array, id) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].ProductCode === id) return array[i];
        }
        return false;
    }


    useEffect(() => {
        loadDeliveryAddresses()
        loadDeliveryDays()
        loadNextDeliveryDate()
        loadCustomer()
        loadReserves()
        totalBottles = 0
    }, [userName])
    return (
        <div className="page checkout-page">
            <h2 className='page-title'>Checkout</h2>
            <div className="checkout__form">
                <div className="checkout__first_step">
                    <Loading isLoading={isDeliveryLoading}/>
                    <div className="checkout__delivery_list">
                        {!isDeliveryLoading && <h3 className={"checkout__details_title"}>Delivery Address</h3>}
                        {!delivery.error && userName && !isDeliveryLoading ? delivery.map((el, index) => (
                            <div className="checkout__delivery_item" key={index}>
                                <input type="radio"
                                       id={'delivery-' + (index + 1)}
                                       value={index} name="delivery"
                                       checked={deliveryIndex == index}
                                       onChange={(e) => onChangeHandler(e.target.value, el.CustomerAccount)}
                                />
                                <label htmlFor={'delivery-' + (index + 1)} className="checkout__delivery_info">
                                    <h3 className='checkout__delivery_title checkout__delivery-customer-title'>{el.CustomerAccount}</h3>
                                    <p className='checkout__delivery_field checkout__delivery-name'>{el.Delivery_Name}</p>
                                    {el.Delivery_Address1.length > 1 &&
                                        <p className='checkout__delivery_field checkout__delivery-address'>{el.Delivery_Address1}</p>}
                                    {el.Delivery_Address2.length > 1 &&
                                        <p className='checkout__delivery_field checkout__delivery-address'>{el.Delivery_Address2}</p>}
                                    {el.Delivery_Address3.length > 1 &&
                                        <p className='checkout__delivery_field checkout__delivery-address'>{el.Delivery_Address3}</p>}
                                    {el.Delivery_Address4.length > 1 &&
                                        <p className='checkout__delivery_field checkout__delivery-address'>{el.Delivery_Address4}</p>}
                                    <div className="checkout__details_field">
                                        {el.DeliveryNotes1.length > 1 &&
                                            <><h3
                                                className='checkout__delivery_title checkout__delivery-notes-title'>Delivery
                                                Notes:</h3>
                                                <p className='checkout__delivery_field checkout__delivery-notes'>{el.DeliveryNotes1}</p></>}
                                        {el.DeliveryNotes2.length > 1 &&
                                            <p className='checkout__delivery_field checkout__delivery-notes'>{el.DeliveryNotes2}</p>}
                                    </div>
                                </label>

                            </div>
                        )) : (
                            delivery.error && <div className="error-message">{delivery.error}</div>
                        )}
                    </div>
                    {!customDelivery && !isDeliveryLoading && process.env.REACT_APP_DELIVERY_DATES === "1" &&
                        <>
                            <h3 className={"checkout__details_title"}>Delivery Date</h3>
                            <DatePicker selected={selectedDate ?? startDate} minDate={startDate} onChange={(date) => {
                                const d = new Date(date);
                                setSelectedDate(d);
                                setDeliveryDateFormatted(d.toLocaleDateString("en-UK"));
                            }}
                                        filterDate={isWeekday}
                                        dateFormat={'eeee d/M/yyyy'}

                            />
                        </>
                    }

                    {customDelivery && !isDeliveryLoading &&
                        <>
                            <h3 className={"checkout__details_title"}>Collection Date and Time</h3>
                            <div className={"cutoff-msg"}>Please provide a preferred Collection date and time to help us
                                prepare your order
                            </div>
                            <DatePicker selected={selectedDate ?? startDate} minDate={startDate} onChange={(date) => {
                                const d = new Date(date);
                                setSelectedDate(d);
                                setDeliveryDateFormatted(d.toLocaleDateString("en-UK"));
                                setDeliveryTimeFormatted(d.toLocaleTimeString("en-UK", {
                                    hour: '2-digit',
                                    minute: '2-digit'
                                }));
                            }}
                                        dateFormat={'eeee d/M/yyyy - HH:mm'}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={60}
                                        filterTime={filterTime}
                            />
                        </>
                    }


                    {!customDelivery && process.env.REACT_APP_DELIVERY_CUTOFF && deliveryDateFormatted === tomorrow.toLocaleDateString("en-UK") &&
                        <div className={"cutoff-msg"}>Orders placed
                            after {process.env.REACT_APP_DELIVERY_CUTOFF} cannot be guaranteed for delivery next
                            day, and will be delivered on your next scheduled delivery day
                        </div>
                    }

                </div>
                <div className="checkout__second_step">
                    <div className="checkout__details">
                        <div className={"checkout__basket_list"}>
                            <BasketListCheckout/>
                        </div>
                        <div className="checkout__details_field">
                            <h3 className="checkout__details_title">Customer Reference / Purchase
                                Order: {customer.MandatoryOrderNo && <span class={"required"}>*</span>}</h3>
                            <input className="checkout__details_input" type="text" name="customerRef" required
                                   value={customerRef} onChange={(e) => setCustomerRef(e.target.value)}/>
                        </div>

                        <div className="checkout__details_field">
                            <h3 className="checkout__details_title">Delivery Notes:</h3>
                            <textarea className="checkout__details_textarea" value={deliveryNotes}
                                      onChange={(e) => setDeliveryNotes(e.target.value)}></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <div className="checkout__submit_wrapper flex-def flex-center">
                {
                    requiredQtyBottles <= totalBottles || totalKegs > 0 ? (
                        !delivery.error &&
                        <div className={'payment-methods'}>
                            {customer.MandatoryOrderNo && customerRef === "" &&
                                <div className={"required-ref-msg"}>Please enter a Customer Reference / Purchase
                                    Order</div>
                            }
                            {(customer.AvailableCredit <= grandTotal && process.env.REACT_APP_ALLOW_NEGATIVE_BALANCE == "1") &&
                                <div className={"credit-msg"}>Insufficent Credit Balance. You may still Pay on Account
                                    however your order will be placed on hold and we will contact you regarding release.
                                </div>
                            }
                            {process.env.REACT_APP_PAYMENT === "1" && !isDeliveryLoading &&
                                <Modules.Fallback fallback={null}>
                                    <Modules.Stripe btnCardRef={btnCardRef} btnCreditRef={btnCreditRef}
                                                    deliveryIndex={deliveryIndex} customerRef={customerRef}
                                                    deliveryNotes={deliveryNotes} cart={cart} setLoading={setLoading}
                                                    getIdTokenClaims={getIdTokenClaims} setIsErrorM={setIsErrorM}
                                                    setMessage={setMessage} setOrderN={setOrderN} dispatch={dispatch}
                                                    delivery={delivery} history={history} userName={userName}
                                                    deliveryDate={deliveryDateFormatted ?? deliveryDate}
                                                    deliveryTime={deliveryTimeFormatted}
                                                    mandatoryOrderNo={customer.MandatoryOrderNo}
                                                    overrideDeliveryAddress={overrideDeliveryAddress}/>
                                </Modules.Fallback>
                            }
                            {process.env.REACT_APP_CREDIT === "1" && (customer.AvailableCredit >= grandTotal || process.env.REACT_APP_ALLOW_NEGATIVE_BALANCE == "1") && !isDeliveryLoading &&
                                <Modules.Fallback fallback={null}>
                                    <Modules.Credit btnCardRef={btnCardRef} btnCreditRef={btnCreditRef}
                                                    deliveryIndex={deliveryIndex} customerRef={customerRef}
                                                    deliveryNotes={deliveryNotes} cart={cart} setLoading={setLoading}
                                                    getIdTokenClaims={getIdTokenClaims} setIsErrorM={setIsErrorM}
                                                    setMessage={setMessage} setOrderN={setOrderN} dispatch={dispatch}
                                                    delivery={delivery} history={history} grandTotal={grandTotal}
                                                    creditLimit={customer.AvailableCredit} userName={userName}
                                                    deliveryDate={deliveryDateFormatted ?? deliveryDate}
                                                    deliveryTime={deliveryTimeFormatted}
                                                    mandatoryOrderNo={customer.MandatoryOrderNo}
                                                    overrideDeliveryAddress={overrideDeliveryAddress}/>
                                </Modules.Fallback>
                            }

                        </div>
                    ) : (<>
                        {!delivery.error &&
                            <Popup trigger={<button className="checkout__submit grey">Submit</button>} arrow={false}
                                   modal
                                   nested>
                                {close => (<>
                                    <img src={logo} className="header-logo-img" alt="Alexander Wines"/>
                                    <p>Under minimum order quantity of {requiredQtyBottles} bottles. Please
                                        add {requiredQtyBottles - totalBottles} bottles to checkout.</p>
                                    <div className='close_modal' onClick={() => close()}>&times;</div>
                                </>)}
                            </Popup>

                        }
                        <p className='required-qty-msg'>Under minimum order quantity of {requiredQtyBottles} bottles.
                            Please add {requiredQtyBottles - totalBottles} bottles to checkout.</p>
                    </>)

                }
                <Error isErrorM={isErrorM} Message={Message} OrderNo={OrderN}/>

            </div>
        </div>
    )
}

export default Checkout

