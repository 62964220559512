import React, {useContext, useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {customerFields} from "../settings";
import {fetchCustomer, fetchCustomerAccounts} from "../apiRequests/api";
import Selects from "./Selects";
import {SigninContext} from "../contexts/SigninContext";
import '../sass/header/profile.scss';
import * as Modules from "./Modules";
import Popup from "reactjs-popup";

function Profile() {
    const {userName, setUserName, setDisplayProfile} = useContext(SigninContext)
    const [customer, setCustomer] = useState([])
    const [selectsLoading, setSelectsLoading] = useState(true)
    const [filterStates, setFilterStates] = useState(customerFields.map(el => ''))
    const [filterInfo, setFilterInfo] = useState(customerFields.map(el => el))
    const {getIdTokenClaims} = useAuth0()
    let fetchParameters = {
        "CustomerAccount": userName
    }
    const { logout } = useAuth0();

    function logoutUser () {
        logout({ returnTo: window.location.origin })
    }
  // we need to request options first from api to be rendered for search selects
    async function loadOptions() {
        const token = await getIdTokenClaims()
        const data = await fetchCustomerAccounts(token.__raw)
        if (!data.error) {
            setFilterInfo(filterInfo.map(state => {
                if (state.select) {
                    state.options = data.mainResult[state.name]
                    setUserName(state.options[0].value)
                    return state
                } else {

                    return state
                }
            }))
            setSelectsLoading(false)
        } else {
            alert('Error loading customer data: Login email not found. Please contact us on ' + process.env.REACT_APP_PHONE + ' or email ' + process.env.REACT_APP_EMAIL)
            logoutUser()
        }
    }


         async function loadCustomer() {

                const token = await getIdTokenClaims()
                const data1 = await fetchCustomer(fetchParameters, token.__raw)
                if (!data1.error) {
                    setCustomer(data1.CreditLimit)
                }
                setCustomer(data1)
                console.log(data1.error)
            }



    function handleFilterChange(val, order) {
        const newArr = filterStates
        newArr[order] = val
        setFilterStates(filterStates.map((el, index) => index + 1 === order ? val : el))
        setUserName(val)
     }

    useEffect(() => {
        loadOptions()
        loadCustomer()
           }, [])


    return (
        <div className="customer-profile">

            <div className={'container'}>

                {/*<div class={'credit'}>*/}
                {/*    {customer != '' &&*/}
                {/*        <>Available Credit: £{customer.AvailableCredit} of £{customer.CreditLimit}</>*/}
                {/*    }*/}
                {/*</div>*/}

                {!selectsLoading &&  customerFields.map
            ((field, index) => (


                <Selects options={filterInfo[index].options} setPropsValue={handleFilterChange}
                         value={userName} order={index + 1} defaultValue={userName}/>

            ))}
                <div className='icon-cont'>
                {process.env.REACT_APP_MYREP === "1" &&
                    <Popup className="my-rep-container"
                           trigger={<span className='nav-linked-in' title={'Account Manager'}><i
                               className="fa-2x fa-thin fa-address-card"></i></span>} arrow={false}
                           modal
                           nested>
                        {close => (<>
                            <Modules.Fallback fallback={null}>
                                <Modules.MyRep/>
                            </Modules.Fallback>
                            <div className='close_modal' onClick={() => close()}>&times;</div>
                        </>)}
                    </Popup>
                }
                </div>
        </div>

</div>
)
}

export default Profile